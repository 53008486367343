import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { generatePath, Link, useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import NProgress from 'nprogress'
import { useTranslation } from 'react-i18next'
import { Layout } from 'components/Layout'
import { Icon } from 'components/Icon'
import { Button, ButtonVariantEnum } from 'components/Button'
import { PATH_FLOWS } from 'pages/FlowsPage'
import { useProjectBuilds, useProjectQuery, useTeamsQuery } from 'hooks/useApiQuery'
import { getOsName } from 'utils/getOsName'
import { getTechName } from 'utils/getTechName'
import { BuildDto, BuildType, UploadBuildDto } from 'api/models'
import { getTeamDomain } from 'utils/getTeamDomain'
import { BaseHeader } from 'components/header/BaseHeader'
import { PageTitle } from 'components/header/PageTitle'
import { ProjectFlowsPageBreadcrumbs } from 'components/breadcrumbs/ProjectFlowsPageBreadcrumbs'
import { observer } from 'mobx-react-lite'
import { useToaster } from 'hooks/useToaster'
import { capitalizeFirstLetter, extractFileName } from 'utils/stringUtils'
import { Modal } from 'components/Modal'
import { FileField } from 'components/flows/ra/FileField'
import classNames from 'classnames'
import { SettingsInput } from 'components/flows/ra/settings/modal/SettingsInput'
import { useApi } from 'contexts/di-context'
import { TFunction } from 'i18next'
import { exhaustiveGuard } from 'utils/misc'
import { LoadingSpinner } from 'components/LoadingSpinner'
import { BuildDtoUploadState } from 'api/__generated__/api-constants'

export const PATH_PROJECT_BUILDS = '/projects/:projectUrlName/project-builds'

function hasTenMinutesPassed(date: Date): boolean {
  const now = new Date()
  const diffInMilliseconds = now.getTime() - date.getTime()
  const tenMinutesInMilliseconds = 10 * 60 * 1000
  return diffInMilliseconds > tenMinutesInMilliseconds
}

export const ProjectBuildsPage = () => {
  const { t } = useTranslation()
  const { projectUrlName } = useParams() as { projectUrlName: string }

  const { isSuccess: isTeamsSuccess } = useTeamsQuery()
  const { isSuccess: isProjectUsersSuccess, data: project } = useProjectQuery({
    projectUrlName,
  })

  const {
    isSuccess: isBuildsSuccess,
    data: unsortedProjectBuildsData,
    refetch: refetchProjectBuilds,
  } = useProjectBuilds({
    projectUrlName,
  })

  const projectBuildsData = unsortedProjectBuildsData
    ?.map((build) => {
      if (build.uploadState === 'UPLOADING' && hasTenMinutesPassed(new Date(build.dateCreated))) {
        return { ...build, uploadState: BuildDtoUploadState.FAILED }
      }
      return build
    })
    .sort(({ id: prevID }, { id: nextId }) => nextId - prevID)

  const [projectUploadBuildModalOpened, setProjectUploadBuildModalOpened] = useState(false)
  const handleUploadClick = () => setProjectUploadBuildModalOpened(true)
  const handleModalClose = async () => {
    setProjectUploadBuildModalOpened(false)
    refetchProjectBuilds()
  }

  const isLoaded = isTeamsSuccess && isProjectUsersSuccess && isBuildsSuccess

  useEffect(() => {
    if (isLoaded) {
      NProgress.done()
    } else {
      NProgress.start()
    }
  }, [isLoaded])

  const header = useMemo(
    () => (
      <BaseHeader
        leftSideContent={<ProjectFlowsPageBreadcrumbs />}
        centerContent={<PageTitle titleKey="projectContributors" />}
      />
    ),
    [],
  )

  return (
    <Layout
      headerComponent={header}
      pageConfig={{
        withoutStyledContent: true,
      }}
    >
      {isLoaded && (
        <main className="flex flex-1">
          {isProjectUsersSuccess && (
            <div className="flex-1 overflow-hidden">
              <div className="pr-[24px] pl-[109px] 1920:pl-[162px]">
                <div className="relative flex items-center py-[28px]">
                  <Icon
                    as={Link}
                    to={generatePath(PATH_FLOWS, { projectUrlName })}
                    icon="arrow-round-l"
                    aria-label={t('goToBack')}
                    className="text-icon transition-colors absolute left-[-85px] mt-[3px] text-gray-normal hover:text-white 1920:left-[-138px]"
                  />
                  <div className="flex-1">
                    <h1 className="inline-block align-middle text-header-small mr-[16px]">
                      {project?.name}
                    </h1>
                    <div className="inline-block mr-[16px] align-middle shrink-0 text-small tracking-wide text-gray-normal">
                      {t('projects.projectContributorsPage.created')}{' '}
                      {dayjs(project?.dateCreated).format('MMMM DD YYYY')}
                    </div>
                  </div>
                  <div className="flex items-center shrink-0">
                    <Button
                      variant={ButtonVariantEnum.Outlined}
                      onClick={handleUploadClick}
                      isSmall
                    >
                      {t('ra.flow.build.upload.label')}
                    </Button>
                  </div>
                </div>
              </div>
              <div className="pb-[68px]">
                <div className="flex pl-[109px] 1920:pl-[162px]">
                  <div className="shrink-0 w-[200px] pr-[8px] 1920:w-[308px]">
                    {project?.image && (
                      <img
                        src={project?.image.tq.url}
                        alt=""
                        aria-hidden="true"
                        className="w-[108px] h-[108px] mb-[10px] rounded-[28px] object-cover"
                      />
                    )}
                    <div className="text-gray-normal text-normal tracking-wide">
                      <div className="text-white">{getOsName(project?.os)}</div>
                      <div>{getTechName(project?.tech)}</div>
                      <div className="break-words">{project?.appId}</div>
                    </div>
                    <div className="mt-[15px] pt-[10px] border-t border-t-white/10 text-normal tracking-wide">
                      {project?.team.domain && <div>{getTeamDomain(project?.team.domain)}</div>}
                    </div>
                  </div>
                  <div className="flex-1 pr-[24px] overflow-x-auto">
                    {projectBuildsData?.length && <BuildGrid builds={projectBuildsData} />}
                  </div>
                </div>
              </div>
            </div>
          )}
        </main>
      )}
      <UploadBuildModal isOpen={projectUploadBuildModalOpened} onClose={handleModalClose} />
    </Layout>
  )
}

interface UploadBuildModalProps {
  isOpen: boolean
  onClose: () => void
}

const UploadBuildModal = (props: UploadBuildModalProps) => {
  const { t } = useTranslation()
  const toaster = useToaster()

  const api = useApi()
  const { projectUrlName } = useParams()

  const { isOpen, onClose } = props

  // reset the form when the modal is closed
  useEffect(() => {
    if (isOpen) {
      setInstrumentedFile(null)
      setName('')
      setDescription('')
    }
  }, [isOpen])

  const [instrumentedFile, setInstrumentedFile] = useState<File | null>(null)
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')

  const isValid = useMemo((): boolean => {
    return instrumentedFile != null
  }, [instrumentedFile])

  const uploadBuild = useCallback(() => {
    if (!isValid) {
      return
    }
    NProgress.start()
    const buildFileName = extractFileName(instrumentedFile!.name)
    const instrumentedDto: UploadBuildDto = {
      buildType: BuildType.INSTRUMENTED_APK,
      buildFileName,
      name: name,
      description: description,
    }
    api
      .postProjectBuild(projectUrlName!, instrumentedDto)
      .then((resp) => {
        return api.uploadTraceToObjectStorage(instrumentedFile!, resp.uploadSpec)
      })
      .catch((reason) => toaster.error(reason, 'ra.flow.build.modalErrorTitle'))
      .finally(() => {
        NProgress.done()
        onClose()
      })
  }, [isValid, instrumentedFile, name, description, api, projectUrlName, toaster, onClose])

  return (
    <Modal
      title={t('ra.flow.build.upload.label')}
      isOpen={isOpen}
      onClose={() => onClose()}
      actionButton={{
        children: t('ra.flow.build.upload.button'),
        onClick: () => uploadBuild(),
        disabled: !isValid,
      }}
    >
      <div className="w-full text-small text-gray-normal space-y-8">
        <div className="space-y-4">
          <div>
            <p className="mb-1">{t('ra.flow.build.instrumentedBuild.label')}</p>
            <div className="flex items-center">
              <div className="basis-2/5">
                <FileField
                  onUpload={(file) => setInstrumentedFile(file)}
                  acceptType="application/vnd.android.package-archive"
                />
              </div>
              <span
                className={classNames(
                  'basis-3/5 pl-4 line-clamp-2 break-words',
                  instrumentedFile && 'text-white',
                )}
              >
                {extractFileName(instrumentedFile?.name || t('ra.flow.settings.notSet'))}
              </span>
            </div>
          </div>
          <SettingsInput
            label={t('ra.flow.build.name.label')}
            value={name}
            onChange={(value) => setName(value)}
          />
          <SettingsInput
            label={t('ra.flow.build.description.label')}
            value={description}
            onChange={(value) => setDescription(value)}
          />
        </div>
      </div>
    </Modal>
  )
}

const enum BuildCol {
  UPLOAD_ID,
  BUILD_FILE_NAME,
  NAME,
  DESCRIPTION,
  AUTHOR,
  UPLOAD_DATE,
  UPLOAD_STATE,
}

const BUILD_COLS: BuildCol[] = [
  BuildCol.UPLOAD_ID,
  BuildCol.BUILD_FILE_NAME,
  BuildCol.NAME,
  BuildCol.DESCRIPTION,
  BuildCol.AUTHOR,
  BuildCol.UPLOAD_DATE,
  BuildCol.UPLOAD_STATE,
]

interface BuildsGridProps {
  builds: BuildDto[]
}

const BuildGrid = observer(function BuildGrid(props: BuildsGridProps) {
  const { builds } = props
  return (
    <>
      <div
        className={classNames('grid')}
        style={{
          gridTemplateColumns: BUILD_COLS.map((col) => widthByCol(col)).join(' '),
        }}
      >
        <Head />
      </div>
      <div
        className={classNames('grid auto-rows-[minmax(50px,max-content)]')}
        style={{
          gridTemplateColumns: BUILD_COLS.map((col) => widthByCol(col)).join(' '),
        }}
      >
        {builds.map((build, index) => (
          <Row key={index} build={build} />
        ))}
      </div>
    </>
  )
})

const Head = () => {
  const { t } = useTranslation()
  return (
    <>
      {BUILD_COLS.map((col, index) => {
        return (
          <div
            key={index}
            className={classNames(
              'px-4 py-2 bg-dark-dark3 text-small text-gray-normal',
              isCenteredCol(col) && 'text-center',
            )}
          >
            <span className="block truncate">{titleByCol(t, col)}</span>
          </div>
        )
      })}
    </>
  )
}

const Row = ({ build }: { build: BuildDto }) => {
  const api = useApi()
  const toaster = useToaster()
  const { projectUrlName } = useParams() as { projectUrlName: string }

  const [isInProgress, setIsInProgress] = useState(false)

  const handleDownload = useCallback(
    async (buildData: BuildDto) => {
      if (isInProgress) {
        return
      }
      try {
        setIsInProgress(true)

        const { id: projectBuildId, buildFileName } = buildData

        const response = await api.getBuildDownloadUrl(projectUrlName, String(projectBuildId))
        const blob = await response.blob()
        const link = document.createElement('a')

        const url = window.URL.createObjectURL(blob)
        link.href = url
        link.setAttribute('download', buildFileName)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        window.URL.revokeObjectURL(url)
      } catch (error) {
        toaster.error(error)
        setIsInProgress(false)
      } finally {
        setIsInProgress(false)
      }
    },
    [api, isInProgress, projectUrlName, toaster],
  )

  return (
    <>
      {BUILD_COLS.map((col, index) => {
        const value = valueByCol(build, col)

        return (
          <div
            key={index}
            className={classNames(
              'flex items-center px-4 py-2 text-normal text-gray-normal',
              isCenteredCol(col) && 'justify-center',
            )}
          >
            {col === BuildCol.UPLOAD_ID ? (
              <span className="line-clamp-3 break-words cursor-pointer">
                {build.uploadState === BuildDtoUploadState.FINISHED &&
                  (!isInProgress ? (
                    <Icon
                      className="text-[32px] rotate-180 inline-block text-gray-normal"
                      icon="upload"
                      onClick={() => handleDownload(build)}
                    />
                  ) : (
                    <LoadingSpinner size={22} className="inline-block" />
                  ))}
              </span>
            ) : (
              <span className="line-clamp-3 break-words">{value}</span>
            )}
          </div>
        )
      })}
    </>
  )
}

const titleByCol = (t: TFunction, col: BuildCol) => {
  let localizationName = ''
  switch (col) {
    case BuildCol.UPLOAD_ID:
      localizationName = 'none'
      break
    case BuildCol.BUILD_FILE_NAME:
      localizationName = 'instrumentedBuildFileName'
      break
    case BuildCol.NAME:
      localizationName = 'name'
      break
    case BuildCol.DESCRIPTION:
      localizationName = 'description'
      break
    case BuildCol.AUTHOR:
      localizationName = 'uploader'
      break
    case BuildCol.UPLOAD_DATE:
      localizationName = 'uploadDate'
      break
    case BuildCol.UPLOAD_STATE:
      localizationName = 'uploadState'
      break
    default:
      exhaustiveGuard(col)
  }
  return t(`ra.flow.build.${localizationName}.label`)
}

const valueByCol = (build: BuildDto, col: BuildCol) => {
  switch (col) {
    case BuildCol.UPLOAD_ID:
      return build.id
    case BuildCol.BUILD_FILE_NAME:
      return build.buildFileName
    case BuildCol.NAME:
      return build.name
    case BuildCol.DESCRIPTION:
      return build.description
    case BuildCol.AUTHOR:
      return build.author ? `${build.author.name} ${build.author.lastName}` : ''
    case BuildCol.UPLOAD_DATE:
      return new Date(build.dateCreated).toLocaleString()
    case BuildCol.UPLOAD_STATE:
      return capitalizeFirstLetter(build.uploadState.toLowerCase())
    default:
      exhaustiveGuard(col)
  }
}

const widthByCol = (col: BuildCol) => {
  switch (col) {
    case BuildCol.UPLOAD_ID:
      return '65px'
    case BuildCol.BUILD_FILE_NAME:
      return '240px'
    case BuildCol.NAME:
      return '240px'
    case BuildCol.DESCRIPTION:
      return '240px'
    case BuildCol.AUTHOR:
      return '150px'
    case BuildCol.UPLOAD_DATE:
      return '180px'
    case BuildCol.UPLOAD_STATE:
      return '150px'
    default:
      exhaustiveGuard(col)
  }
}

const isCenteredCol = (col: BuildCol): boolean => {
  switch (col) {
    case BuildCol.UPLOAD_ID:
    case BuildCol.UPLOAD_STATE:
    case BuildCol.UPLOAD_DATE:
      return true
    case BuildCol.BUILD_FILE_NAME:
    case BuildCol.NAME:
    case BuildCol.DESCRIPTION:
    case BuildCol.AUTHOR:
      return false
    default:
      exhaustiveGuard(col)
  }
}
